import React from "react"

import Logo from './logo'
import Button from './button'

import data from '../../content/index.yaml'

const Header = () => (
  <header className="c-header">
    <Logo />

    <nav className="c-menu">
      <ul>
        {data.header.menu.map(mItem => {
          return (
            <li key={mItem.href}>
              <Button type="link" href={mItem.href} title={mItem.title} />
              <div className="c-menu__animation-helper" />
            </li>
          )
        })}
      </ul>
    </nav>
  </header>
)

export default Header
