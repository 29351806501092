import React from 'react'

const Button = props => {
  if (props.type === 'link') {
    return (
      <a
        className="c-button c-button--link"
        href={props.href}
        dangerouslySetInnerHTML={{ __html: props.title }}
      />
    )
  }
  return (
    <button
      className="c-button"
      name={props.name ? props.name : ''}
      type={props.nativeType ? props.nativeType : 'button'}
      dangerouslySetInnerHTML={{ __html: props.title }}
      onClick={props.onClick}
    />
  )
}

export default Button
